/* .react-chatbot-kit-chat-container {
    width:40vw !important;
    max-height: 90vh !important;
    max-width: 68vw !important;
    height: 100vh !important;
    margin-left: 25% !important;
    background-color: white !important;
    transition: transform 0.05s ease-in-out !important;
    cursor: pointer !important;
    animation: slideInRight 0.5s ease-in-out !important;
    margin-top: 3%;
    margin-bottom: 5% !important;
  } */

  .react-chatbot-kit-chat-container {
    width: 68vw !important;
    max-height: 90vh !important;
    max-width: 68vw !important;
    height: 100vh !important;
    margin-left: 25% !important;
    background-color: white !important;
    transition: transform 0.05s ease-in-out !important;
    cursor: pointer !important;
    animation: slideInRight 0.5s ease-in-out !important;
    margin-top: 4.5%;
    margin-bottom: 5% !important;
  }
  .react-chatbot-kit-chat-message-container{
    height: 50vh!important;
    overflow: auto !important;
  }
  .react-chatbot-kit-chat-header{
    display: none !important;
  }
  .react-chatbot-kit-chat-bot-message {
    background-color: #6f9d68 !important;
    font-size: 0.7rem !important;
    margin-left: 15px !important;
    width: max-content !important;
    max-width: 70vh !important;
  }
  .react-chatbot-kit-chat-bot-message-arrow{
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #6f9d68;
    position: absolute;
    left: -7px;
    top: 13px;
  }
  .react-chatbot-kit-user-chat-message {
    font-size: 0.7rem !important;
    margin-right: 15px ;
  }
  .react-chatbot-kit-chat-input-container{
    bottom:43% !important;
  }
  input:focus{
    outline: none;
    border-color: none !important;
  }

  .range{
    color: #052c65 !important;
    position: absolute;
    color: white;
    margin-left: 70%;
    margin-top: -135vh;
    accent-color:  rgba(191, 176, 65, 0.823);
    font-size: small;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: italic;
  }
 
  
  .cb{
    max-height: 100vh;
    max-width: 100vh;
    transition: transform 0.05s ease-in-out;
    cursor: pointer;
    animation: slideInRight 1s ease-in-out;
  }
  @keyframes slideInRight {
    0% {
      transform: translateX(50%); 
      opacity: 0;
    }
    100% {
      transform: translateX(0); 
      opacity: 1;
    }
  }
  
  
  @media screen and (max-width: 600px)
  {
    input[type="range"] {
      width: 8px;
      height: 175px;
      padding: 0 5px;
      -webkit-appearance: slider-vertical;
    }
    .word{
      font-size: 0.4rem;
      margin-left: -5vw;
    }
    .range{
     margin-top: -39vh;
     margin-left: -10vw;
    }
    .react-chatbot-kit-chat-container {
  width: 68vw !important;
  height: 64vh !important;
    }
    .pdfu{
      margin-left: -4vw;
      margin-top: 10vh;
      text-align: left;
    
    }
    .updf{
      font-size: 0.6rem;
      margin-left: -1vw;
     
    }
  .pdf{
    font-size: 5px;
    width: 10vw;
  }
  input[type="file"] {
    padding: 0px;
  }
  .upload{
    font-size: 0.6rem;
  }
  
  
  
  
  .react-chatbot-kit-chat-bot-message{
    font-size: 10px !important;
    width: 25vw !important;
  }
  .react-chatbot-kit-user-chat-message{
    font-size: 10px !important;
    width: 25vw !important;
  }
  .start{
    font-size: 8px;
    margin-left: 8vw;
  }
  
  }
  
  .shapes {
    margin-left: 60%;
    margin-top: -40%;
    position: absolute;
    width: 44.8px;
    height: 44.8px;
    color: #1363c6;
    position: relative;
  }
  
  .shapes:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background: conic-gradient(from 0deg,  #0000, currentColor 1deg 120deg,#0000 121deg) top right,
           conic-gradient(from 120deg,#0000, currentColor 1deg 120deg,#0000 121deg) bottom,
           conic-gradient(from 240deg,#0000, currentColor 1deg 120deg,#0000 121deg) top left;
    background-size: 44.8px 44.8px;
    background-repeat: no-repeat;
    animation: shapes-r0t0iomd 2s infinite cubic-bezier(0.3,1,0,1);
  }
  
  @keyframes shapes-r0t0iomd {
    33% {
       inset: -9px;
       transform: rotate(0deg);
    }
  
    66% {
       inset: -9px;
       transform: rotate(180deg);
    }
  
    100% {
       inset: 0;
       transform: rotate(180deg);
    }
  }